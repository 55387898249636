import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './ModalAnexoANatural.scss';
import iconClose from '../../assets/svg/icon-close.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeAnexoAData,
    // changeAnexoAError,
    changeDatosFinalesField,
} from '../../redux/reducers/naturalForm';
export const ModalAnexoANatural = ({ show, setShow }: any) => {
    const dispatch = useDispatch();
    const anexoAdata = useSelector((state: any) => state.naturalForm.anexoAData);
    const anexoAErrors = useSelector((state: any) => state.naturalForm.anexoAErrors);

    // const validateAnexoAData = () => {
    //     let isValid = true;
    //     Object.entries(anexoAdata).map((item: any) => {
    //         dispatch(changeAnexoAError({ field: item[0], content: item[1] === '' }));
    //         if (item[1] === '') isValid = false;
    //         return null
    //     });
    //     return isValid;
    // };
    return (
        <Modal isOpen={show} toggle={() => setShow(!show)} size="xl" centered>
            <ModalBody className="p-0 ">
                <div className="container">
                    <div className="row py-3 px-2">
                        <div className="col-12 pru ">
                            <img
                                onClick={() => setShow(!show)}
                                src={iconClose}
                                alt="close-icon"
                                style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                    <div className="container anexo-b-page">
                        <div className="row mb-5">
                            <div className="col-12">
                                <div className="row d-flex justify-content-center align-center mt-5">
                                    <div className="col-xl-3 col-lg-3 col-md-12 col-xs-6 anexo-b-title">
                                        <span>ANEXO A</span>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center align-center">
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-xs-6 anexo-b-subtitle-gray">
                                        (FICHA CLIENTE PERSONA NATURAL)
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row  d-flex justify-content-center align-center">
                            <div className="col-xl-10 col-lg-10 col-md-12 col-xs-6 anexo-content-b mt-2">
                                <div>
                                    <span>
                                        Antecedentes adicionales del Administrador de Cartera (Persona Natural) INSCRITO
                                        en el Registro de Administradores de Carteras que lleva la Comisión para el
                                        Mercado Financiero:
                                        <br />
                                        <br />
                                        Por favor marcar con una “X”, en caso que Usted cumpla alguna de las siguientes
                                        <br />
                                        condiciones:
                                    </span>
                                </div>
                                <div className="row d-flex justify-content-end ">
                                    <div className="col-xl-10 col-lg-10 col-md-12 col-xs-6">
                                        <div className="input-checkbox-box">
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoAdata.checkbox1 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="Si"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoAData({
                                                                field: 'checkbox1',
                                                                content: `${anexoAdata.checkbox1 !== 'O' ? 'O' : 'X'}`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    Administra carteras para 50 o más mandantes no pertenecientes a la
                                                    misma familia, conforme a la definición contenida en la letra d) del
                                                    artículo 1°de la LUF, por un monto total igual o superior al
                                                    equivalente a 500.000 Unidades de Fomento; o
                                                </label>
                                            </div>
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoAdata.checkbox2 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="No"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoAData({
                                                                field: 'checkbox2',
                                                                content: `${anexoAdata.checkbox2 !== 'O' ? 'O' : 'X'}`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    Administra una o más carteras por un monto total igual o superior al
                                                    equivalente a 1.000.000 Unidades de Fomento.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col-lg-12 col-md-12 col-xs-6 anexo-content-b">
                                        <span>
                                            Si Usted ha marcado alguna de las alternativas anteriores, por favor
                                            completar y firmar la declaración siguiente:
                                        </span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className=" col-lg-12 col-md-12 col-xs-6 anexo-content-b">
                                        <span>
                                            DECLARACIÓN DE INVERSIONISTA INSTITUCIONAL <br /> (CLIENTE PERSONA NATURAL){' '}
                                            <br /> La Suscrita, en su calidad de Inversionista Institucional de acuerdo
                                            a lo establecido en la letra d) de la Sección I de la Norma de Carácter
                                            General Nº 410, de 2016 (en adelante, la “NCG N° 410”), de la
                                            Superintendencia de Valores y Seguros, hoy Comisión para el Mercado
                                            Financiero (en adelante, la “CMF”), por el presente instrumento viene en
                                            declarar bajo juramento su intención expresa de participar e invertir en tal
                                            calidad en el mercado financiero, a través de instrumentos con condiciones y
                                            requisitos específicos, entre los cuales se encuentran, a título meramente
                                            ejemplar y enunciativo, valores de oferta pública de emisores nacionales y/o
                                            extranjeros; cuotas o series de cuotas de fondos mutuos y/o de fondos de
                                            inversión nacionales dirigidos a Inversionistas Institucionales; acciones o
                                            participaciones en vehículos de inversión colectiva extranjeros; contratos
                                            de derivados celebrados en Chile o en el extranjero, con el propósito de
                                            cubrir el riesgo de fluctuaciones de tasas, precios y tipos de cambio de los
                                            activos administrados; contratos sobre productos agropecuarios que se
                                            transen en bolsas de productos nacionales; facturas que sean adquiridas en
                                            centros bursátiles nacionales, que cuenten con autorización para transar
                                            este tipo de activos; notas estructuradas; contrato de préstamo de valores;
                                            así como cualquier otro instrumento financiero que pueda desarrollarse en el
                                            futuro. Asimismo, la Suscrita declara expresamente que entiende a cabalidad
                                            y acepta todos los riesgos propios de los mercados especiales y sus
                                            instrumentos, y que cumple con la calidad de Administrador de Cartera
                                            inscrito en el Registro respectivo de la CMF y con los requisitos
                                            específicos para ser considerada Inversionista Institucional, a la luz de lo
                                            establecido en la letra d) de la Sección I de la NCG N° 410 de la CMF.
                                            Declara la Suscrita, finalmente, que ha sido detalladamente informada por
                                            AVANTE ADMINISTRADORA GENERAL DE FONDOS S.A. acerca de las características y
                                            riesgos particulares de los mercados e instrumentos objeto de inversión y,
                                            en particular, lo siguiente:
                                        </span>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-end">
                                    <div className="col-xl-10 col-lg-10 col-md-12 col-xs-12">
                                        <ul>
                                            <li className="my-4">
                                                Que los valores y sus emisores pueden contar con requisitos, parámetros
                                                y riesgos diferentes a aquéllos correspondientes al mercado general de
                                                valores, razón por la que sólo pueden participar Inversionistas
                                                Calificados.
                                            </li>
                                            <li className="my-4">
                                                Que la Comisión para el Mercado Financiero, en consideración a las
                                                características de determinados emisores de valores, al volumen de sus
                                                operaciones u otras circunstancias, está legalmente facultada para
                                                requerirles menor información y circunscribir la transacción de sus
                                                valores a los grupos de inversionistas que determine.
                                            </li>
                                            <li className="my-4">
                                                Que, en virtud de lo anterior, la Comisión para el Mercado Financiero, a
                                                través de Este conocimiento fue adquirido a consecuencia de: Normas de
                                                Carácter General, ha establecido menores requisitos de información para
                                                aquellos emisores qu e pretendan hacer oferta de sus valores en mercado
                                                especiales o dirigirlas exclusivamente a Inversionistas Calificados.
                                            </li>
                                            <li className="my-4">
                                                Que, tratándose de valores extranjeros, la información legal, económica
                                                y financiera corresponderá a aquella que el emisor entrega en sus
                                                mercados de origen o en otro mercados donde los valores se negocian, y
                                                que la oferta pública de valores extranjeros en Chile requiere de parte
                                                de la Comisión para el Mercado Financiero únicamente de la inscripción
                                                en el Registro de Valores Extranjeros que lleva al efecto, por lo cual
                                                sólo le compete la regulación y supervisión de la oferta pública que de
                                                dichos títulos se haga en Chile, con las limitaciones que conlleva que
                                                los emisores respectivos tengan domicilio en el extranjero.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-6 anexo-content-b">
                                        <span>
                                            La presente declaración se suscribe en duplicado, quedando un ejemplar
                                            firmado en poder del Inversionista y el otro en poder de Avante
                                            Administradora General de Fondos S.A.
                                        </span>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-12">
                                        <span className="declarante-form-title">
                                            Declarante cliente persona natural:
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {anexoAErrors.nombre ? <span className="error-color">*</span> : ''}
                                                    Nombre completo del inversionista:
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        anexoAErrors.nombre ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoAdata.nombre}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoAData({
                                                                field: 'nombre',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {anexoAErrors.nombre && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {' '}
                                                    {anexoAErrors.nroCedula ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    N° Cédula de Identidad del Inversionista:
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        anexoAErrors.nroCedula ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoAdata.nroCedula}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoAData({
                                                                field: 'nroCedula',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {anexoAErrors.nroCedula && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {anexoAErrors.nroIdTributario ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    N° de Identificación Tributaria del Inversionista:
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        anexoAErrors.nroIdTributario ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoAdata.nroIdTributario}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoAData({
                                                                field: 'nroIdTributario',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {anexoAErrors.nroIdTributario && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {anexoAErrors.nroRutExtranjero ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    N° RUT del Inversionista Extranjero:
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        anexoAErrors.nroRutExtranjero ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoAdata.nroRutExtranjero}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoAData({
                                                                field: 'nroRutExtranjero',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {anexoAErrors.nroRutExtranjero && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-lg-3 col-md-12 col-sm-4">
                                        <div
                                            className="btn btn-primary btn-avante-primary btn-home d-flex align-items-center justify-content-center"
                                            onClick={() => {
                                                // if (validateAnexoAData()) {
                                                dispatch(changeDatosFinalesField({ field: 'anexoA', content: true }));
                                                setShow(false);
                                                // }
                                            }}
                                        >
                                            Acepto
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
