import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './ModalAnexoN1.scss';
import iconClose from '../../assets/svg/icon-close.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeAnexoN1Field,
    changeDatosFinalesField,
    // changeErrAnexoN1Field
} from '../../redux/reducers/juridicaForm';
// const fields = [
//     'nombreRepresentanteLegal',
//     'nroCedulaId',
//     'nombreRazonSocial',
//     'nroRut',
//     'nroIdTributaria',
//     'nroRutExtranjero',
// ];
export const ModalAnexoN1 = ({ show, setShow }: any) => {
    const dispatch = useDispatch();
    const anexoN1Data = useSelector((state: any) => state.juridicaForm.anexoN1);
    const errAnexoN1Data = useSelector((state: any) => state.juridicaForm.errAnexoN1);
    // const validateAnexoN1Data = () => {
    //     let isValid = true;
    //     for (let i in fields) {
    //         if (anexoN1Data[fields[i]] === '') {
    //             dispatch(changeErrAnexoN1Field({ field: fields[i], content: true }));
    //             isValid = false;
    //         } else {
    //             dispatch(changeErrAnexoN1Field({ field: fields[i], content: false }));
    //         }
    //     }
    //     return isValid;
    // };
    return (
        <Modal isOpen={show} toggle={() => setShow(!show)} size="xl" centered>
            <ModalBody className="p-0 ">
                <div className="container">
                    <div className="row py-3 px-2">
                        <div className="col-12 pru">
                            <img
                                onClick={() => setShow(!show)}
                                src={iconClose}
                                alt="close-icon"
                                style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                    <div className="container anexo-b-page">
                        <div className="row mb-5">
                            <div className="col-12">
                                <div className="row d-flex justify-content-center align-center mt-5">
                                    <div className="col-xl-3  col-lg-3 col-md-12 col-xs-6 anexo-b-title">
                                        <span>ANEXO N°1</span>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center align-center">
                                    <div className="col-xl-12 col-lg-12col-md-12 col-xs-6 anexo-b-subtitle-gray">
                                        (FICHA CLIENTE PERSONA JURÍDICA / ENTIDAD)
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center align-center">
                                    <div className="col-xl-12col-lg-12 col-md-12 col-xs-6 anexo-b-subtitle">
                                        DECLARACIÓN DE INVERSIONISTA INSTITUCIONAL
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row  d-flex justify-content-center align-center">
                            <div className="col-xl-10 col-lg-10 col-md-12 col-xs-6 anexo-content-b mt-2">
                                <div>
                                    <span>
                                        La Suscrita, en su calidad de Inversionista Institucional de acuerdo a lo
                                        establecido en la letra e) del artículo 4° bis de la Ley N° 18.045, Ley de
                                        Mercado de Valores (en adelante, la “LMV”), o en la Sección I de la Norma de
                                        Carácter General Nº 410, de 2016 (en adelante, la “NCG N° 410”), de la
                                        Superintendencia de Valores y Seguros, hoy Comisión para el Mercado Financiero
                                        (en adelante, la “CMF”), por el presente instrumento viene en declarar bajo
                                        juramento su intención expresa de participar e invertir en tal calidad en el
                                        mercado financiero, a través de instrumentos con condiciones y requisitos
                                        específicos, entre los cuales se encuentran, a título meramente ejemplar y
                                        enunciativo, valores de oferta pública de emisores nacionales y/o extranjeros;
                                        cuotas o series de cuotas de fondos mutuos y/o de fondos de inversión nacionales
                                        dirigidos a Inversionistas Institucionales; acciones o participaciones en
                                        vehículos de inversión colectiva extranjeros; contratos de derivados celebrados
                                        en Chile o en el extranjero con el propósito de cubrir el riesgo de
                                        fluctuaciones de tasas, precios y tipos de cambio de los activos administrados;
                                        contratos sobre productos agropecuarios que se transen en bolsas de productos
                                        nacionales; facturas que sean adquiridas en centros bursátiles nacionales, que
                                        cuenten con autorización para transar este tipo de activos; notas estructuradas;
                                        contrato de préstamo de valores; así como cualquier otro instrumento financiero
                                        que pueda desarrollarse en el futuro.
                                        <br />
                                        <br />
                                        Asimismo, la Suscrita declara expresamente que entiende a cabalidad y acepta
                                        todos los riesgos propios de los mercados especiales y sus instrumentos, y que
                                        cumple con alguna de las calidades y requisitos para ser considerada
                                        Inversionista Institucional, a la luz de lo establecido en la letra e) del
                                        artículo 4° bis de la LMV o en la Sección I de la NCG N° 410 de la CMF.
                                        <br />
                                        <br />A mayor abundamiento, y a fin de precisar lo previamente referido, la
                                        Suscrita declara bajo juramento ser (por favor marcar con una “X” lo que
                                        corresponda):
                                        <br />
                                        <br />
                                        a) De acuerdo con la letra e) del artículo 4° bis de la LMV:
                                    </span>
                                </div>
                                <div className="row d-flex justify-content-end ">
                                    <div className="col-xl-10 col-lg-10 col-md-12 col-xs-12">
                                        <div className="input-checkbox-box">
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoN1Data.checkbox1 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="Si"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'checkbox1',
                                                                content: `${anexoN1Data.checkbox1 === 'X' ? 'O' : 'X'}`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">Un banco</label>
                                            </div>
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoN1Data.checkbox2 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="No"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'checkbox2',
                                                                content: `${anexoN1Data.checkbox2 === 'X' ? 'O' : 'X'}`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    Una sociedad financiera
                                                </label>
                                            </div>
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoN1Data.checkbox3 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="No"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'checkbox3',
                                                                content: `${anexoN1Data.checkbox3 === 'X' ? 'O' : 'X'}`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    Una compañía de seguro{' '}
                                                </label>
                                            </div>
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoN1Data.checkbox4 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="No"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'checkbox4',
                                                                content: `${anexoN1Data.checkbox4 === 'X' ? 'O' : 'X'}`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    Una entidad nacional de reaseguro
                                                </label>
                                            </div>
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoN1Data.checkbox5 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="No"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'checkbox5',
                                                                content: `${anexoN1Data.checkbox5 === 'X' ? 'O' : 'X'}`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    Una administradora de fondos autorizados por ley
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3 d-flex justify-content-end ">
                                    <div className="col-12 anexo-content-b ">
                                        <span>O BIEN,</span>
                                    </div>
                                    <div className="col-12 anexo-content-b  mt-2">
                                        <div>
                                            <span>b) De acuerdo con la Sección I de la NCG Nº 410 de la CMF:</span>
                                        </div>
                                    </div>

                                    <div className="col-xl-10 col-lg-10 col-md-12 col-xs-12">
                                        <div className="input-checkbox-box">
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoN1Data.checkbox6 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="Si"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'checkbox6',
                                                                content: `${anexoN1Data.checkbox6 === 'X' ? 'O' : 'X'}`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    Una persona o entidad extranjera, cuyo giro principal está sometido
                                                    a la regulación aplicable al giro bancario, de compañías de seguros
                                                    o reaseguros conforme al marco jurídico aplicable en su país de
                                                    origen.
                                                </label>
                                            </div>
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoN1Data.checkbox7 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="No"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'checkbox7',
                                                                content: `${anexoN1Data.checkbox7 === 'X' ? 'O' : 'X'}`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    {' '}
                                                    Un fondo extranjero u otro tipo de vehículo de inversión colectiva
                                                    extranjero, que cumple con al menos una de las siguientes
                                                    condiciones:
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-end ">
                                            <div className="col-xl-10 col-lg-10 col-md-12 col-xs-12">
                                                <div className="input-checkbox-box">
                                                    <div className="my-4 d-flex flex-row">
                                                        <input
                                                            checked={anexoN1Data.checkbox8 === 'X'}
                                                            className="input-radio mr-3 mt-1"
                                                            type="checkbox"
                                                            name="requisitos"
                                                            value="Si"
                                                            onChange={() =>
                                                                dispatch(
                                                                    changeAnexoN1Field({
                                                                        field: 'checkbox8',
                                                                        content: `${
                                                                            anexoN1Data.checkbox8 === 'X' ? 'O' : 'X'
                                                                        }`,
                                                                    })
                                                                )
                                                            }
                                                        />
                                                        <label className="input-checkbox input-checkbox-text">
                                                            Que el órgano a cargo de sus decisiones de inversión o sus
                                                            propios administradores desempeñando esa función son
                                                            fiscalizados en su calidad de tales por un organismo público
                                                            o privado de similar competencia a la Comisión para el
                                                            Mercado Financiero o a la Superintendencia de Pensiones; o,
                                                        </label>
                                                    </div>
                                                    <div className="my-4 d-flex flex-row">
                                                        <input
                                                            checked={anexoN1Data.checkbox9 === 'X'}
                                                            className="input-radio mr-3 mt-1"
                                                            type="checkbox"
                                                            name="requisitos"
                                                            value="No"
                                                            onChange={() =>
                                                                dispatch(
                                                                    changeAnexoN1Field({
                                                                        field: 'checkbox9',
                                                                        content: `${
                                                                            anexoN1Data.checkbox9 === 'X' ? 'O' : 'X'
                                                                        }`,
                                                                    })
                                                                )
                                                            }
                                                        />
                                                        <label className="input-checkbox input-checkbox-text">
                                                            Que, en su calidad de fondo o vehículo de inversión
                                                            colectiva, está sometido a la fiscalización por parte de un
                                                            organismo de similar competencia a la Comisión para el
                                                            Mercado Financiero o a la Superintendencia de Pensiones .
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-checkbox-box">
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoN1Data.checkbox10 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="Si"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'checkbox10',
                                                                content: `${
                                                                    anexoN1Data.checkbox10 === 'X' ? 'O' : 'X'
                                                                }`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    Un Fondo de Inversión Privado de la Ley Nº 20.712, de 2014, Ley
                                                    sobre Administración de Fondos de Terceros y Carteras Individuales
                                                    (en adelante, la “LUF”), que cumple con al menos una de las
                                                    siguientes condiciones:
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-end ">
                                            <div className="col-xl-10 col-lg-10 col-md-12 col-xs-12">
                                                <div className="input-checkbox-box">
                                                    <div className="my-4 d-flex flex-row">
                                                        <input
                                                            checked={anexoN1Data.checkbox11 === 'X'}
                                                            className="input-radio mr-3 mt-1"
                                                            type="checkbox"
                                                            name="requisitos"
                                                            value="Si"
                                                            onChange={() =>
                                                                dispatch(
                                                                    changeAnexoN1Field({
                                                                        field: 'checkbox11',
                                                                        content: `${
                                                                            anexoN1Data.checkbox11 === 'X' ? 'O' : 'X'
                                                                        }`,
                                                                    })
                                                                )
                                                            }
                                                        />
                                                        <label className="input-checkbox input-checkbox-text">
                                                            Que, durante 12 de los últimos 18 meses (contados a partir
                                                            de la fecha con que se suscribe la presente declaración), ha
                                                            tenido al menos 4 aportantes no relacionados entre sí, y
                                                            cada uno de ellos con más del 10% del patrimonio del Fondo;
                                                            o,
                                                        </label>
                                                    </div>
                                                    <div className="my-4 d-flex flex-row">
                                                        <input
                                                            checked={anexoN1Data.checkbox12 === 'X'}
                                                            className="input-radio mr-3 mt-1"
                                                            type="checkbox"
                                                            name="requisitos"
                                                            value="No"
                                                            onChange={() =>
                                                                dispatch(
                                                                    changeAnexoN1Field({
                                                                        field: 'checkbox12',
                                                                        content: `${
                                                                            anexoN1Data.checkbox12 === 'X' ? 'O' : 'X'
                                                                        }`,
                                                                    })
                                                                )
                                                            }
                                                        />
                                                        <label className="input-checkbox input-checkbox-text">
                                                            Que, durante 12 de los últimos 18 meses (contados a partir
                                                            de la fecha con que se suscribe la presente declaración), ha
                                                            tenido como aportante a uno o más fondos fiscalizados por la
                                                            Comisión para el Mercado Financiero, a compañías de seguros,
                                                            bancos o a las entidades gubernamentales o estatales y
                                                            fondos soberanos autorizados para invertir en instrumentos
                                                            financieros del mercado de capitales, y siempre que la
                                                            participación total que hayan tenido este tipo de entidades
                                                            en el Fondo, durante ese período, sea igual o superior al
                                                            50% de las cuotas pagadas del Fondo.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-checkbox-box">
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoN1Data.checkbox13 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="Si"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'checkbox13',
                                                                content: `${
                                                                    anexoN1Data.checkbox13 === 'X' ? 'O' : 'X'
                                                                }`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    Una persona o entidad inscrita en el Registro de Administradoresde
                                                    Carteras mantenido por la Comisión para el Mercado Financiero, que
                                                    cumpla con al menos una de las siguientes condiciones:
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-end ">
                                            <div className="col-xl-10 col-lg-10 col-md-12 col-xs-12">
                                                <div className="input-checkbox-box">
                                                    <div className="my-4 d-flex flex-row">
                                                        <input
                                                            checked={anexoN1Data.checkbox14 === 'X'}
                                                            className="input-radio mr-3 mt-1"
                                                            type="checkbox"
                                                            name="requisitos"
                                                            value="Si"
                                                            onChange={() =>
                                                                dispatch(
                                                                    changeAnexoN1Field({
                                                                        field: 'checkbox14',
                                                                        content: `${
                                                                            anexoN1Data.checkbox14 === 'X' ? 'O' : 'X'
                                                                        }`,
                                                                    })
                                                                )
                                                            }
                                                        />
                                                        <label className="input-checkbox input-checkbox-text">
                                                            Administrar carteras para 50 o más mandantes no
                                                            pertenecientes a la misma familia, conforme a la definición
                                                            contenida en la letra d) del artículo 1° de la LUF, por un
                                                            monto total igual o superior al equivalente a 500.000
                                                            Unidades de Fomento; o,
                                                        </label>
                                                    </div>
                                                    <div className="my-4 d-flex flex-row">
                                                        <input
                                                            checked={anexoN1Data.checkbox15 === 'X'}
                                                            className="input-radio mr-3 mt-1"
                                                            type="checkbox"
                                                            name="requisitos"
                                                            value="No"
                                                            onChange={() =>
                                                                dispatch(
                                                                    changeAnexoN1Field({
                                                                        field: 'checkbox15',
                                                                        content: `${
                                                                            anexoN1Data.checkbox15 === 'X' ? 'O' : 'X'
                                                                        }`,
                                                                    })
                                                                )
                                                            }
                                                        />
                                                        <label className="input-checkbox input-checkbox-text">
                                                            Administrar una o más carteras por un monto total igual o
                                                            superior al equivalente a 1.000.000 Unidades de Fomento.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-checkbox-box">
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoN1Data.checkbox16 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="Si"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'checkbox16',
                                                                content: `${
                                                                    anexoN1Data.checkbox16 === 'X' ? 'O' : 'X'
                                                                }`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    Una entidad gubernamental o estatal, o un fondo soberano,
                                                    autorizados para invertir en instrumentos financieros del mercado de
                                                    capitales, entre otras inversiones.
                                                </label>
                                            </div>
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoN1Data.checkbox17 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="No"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'checkbox17',
                                                                content: `${
                                                                    anexoN1Data.checkbox17 === 'X' ? 'O' : 'X'
                                                                }`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    Un organismo multilateral, supranacional o entidad creada por varios
                                                    Estados, cuyos recursos tengan por destino promover el desarrollo de
                                                    los mercados de capitales.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 aanexo-content-b">
                                        <span>
                                            Finalmente, la Suscrita declara que ha sido detalladamente informada por
                                            AVANTE ADMINISTRADORA GENERAL DE FONDOS S.A. acerca de las características y
                                            riesgos particulares de los mercados e instrumentos objeto de inversión y,
                                            en particular, lo siguiente:
                                        </span>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-end">
                                    <div className="col-12">
                                        <ul>
                                            <li className="my-4">
                                                Que los valores y sus emisores pueden contar con requisitos, parámetros
                                                y riesgos diferentes a aquéllos correspondientes al mercado general de
                                                valores, razón por la que sólo pueden participar Inversionistas
                                                Institucionales.
                                            </li>
                                            <li className="my-4">
                                                Que la Comisión para el Mercado Financiero, en consideración a las
                                                características de determinados emisores de valores, al volumen de sus
                                                operaciones u otras circunstancias, está legalmente facultada para
                                                requerirles menor información y circunscribir la transacción de sus
                                                valores a los grupos de inversionistas que determine.
                                            </li>
                                            <li className="my-4">
                                                Que, en virtud de lo anterior, la Comisión para el Mercado Financiero, a
                                                través de Normas de Carácter General, ha establecido menores requisitos
                                                de información para aquellos emisores que pretendan hacer oferta de sus
                                                valores en mercado especiales o dirigirlas exclusivamente, entre otros,
                                                a Inversionistas Institucionales.
                                            </li>
                                            <li className="my-4">
                                                Que, tratándose de valores extranjeros, la información legal, económica
                                                y financiera corresponderá a aquella que el emisor entrega en sus
                                                mercados de origen o en otros mercados donde los valores se negocian, y
                                                que la oferta pública de valores extranjeros en Chile requiere de parte
                                                de la Comisión para el Mercado Financiero únicamente de la inscripción
                                                en el Registro de Valores Extranjeros que lleva al efecto, por lo cual
                                                sólo le compete la regulación y supervisión de la oferta pública que de
                                                dichos títulos se haga en Chile, con las limitaciones que conlleva que
                                                los emisores respectivos tengan domicilio en el extranjero.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-12 anexo-content-b">
                                        <span>
                                            La presente declaración se suscribe en duplicado, quedando un ejemplar
                                            firmado en poder del Inversionista y el otro en poder de Avante
                                            Administradora General de Fondos S.A.
                                        </span>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-12">
                                        <span className="declarante-form-title">
                                            Declarante cliente persona jurídica / entidad:
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {errAnexoN1Data.nombreRepresentanteLegal ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    Nombre del Representante Legal del Inversionista:
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        errAnexoN1Data.nombreRepresentanteLegal ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoN1Data.nombreRepresentanteLegal}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'nombreRepresentanteLegal',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {errAnexoN1Data.nombreRepresentanteLegal && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {' '}
                                                    {errAnexoN1Data.nroCedulaId ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    N° Cédula de Identidad del Representante Legal:
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        errAnexoN1Data.nroCedulaId ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoN1Data.nroCedulaId}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'nroCedulaId',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {errAnexoN1Data.nroCedulaId && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {errAnexoN1Data.nombreRazonSocial ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    Nombre o Razón Social del Inversionista: pp.
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        errAnexoN1Data.nombreRazonSocial ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoN1Data.nombreRazonSocial}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'nombreRazonSocial',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {errAnexoN1Data.nombreRazonSocial && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {errAnexoN1Data.nroRut ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    N° RUT del Inversionista:
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        errAnexoN1Data.nroRut ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoN1Data.nroRut}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'nroRut',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {errAnexoN1Data.nroRut && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {errAnexoN1Data.nroIdTributaria ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    N° de Identificación Tributaria del Inversionista Extranjero:
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        errAnexoN1Data.nroIdTributaria ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoN1Data.nroIdTributaria}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'nroIdTributaria',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {errAnexoN1Data.nroIdTributaria && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {errAnexoN1Data.nroRutExtranjero ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    N° RUT del Inversionista Extranjero:
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        errAnexoN1Data.nroRutExtranjero ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoN1Data.nroRutExtranjero}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoN1Field({
                                                                field: 'nroRutExtranjero',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {errAnexoN1Data.nroRutExtranjero && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-lg-3 col-md-12 col-sm-4">
                                        <div
                                            className="btn btn-primary btn-avante-primary btn-home d-flex align-items-center justify-content-center"
                                            onClick={() => {
                                                // if (validateAnexoN1Data()) {
                                                setShow(!show);
                                                dispatch(changeDatosFinalesField({ field: 'anexoN1', content: true }));
                                                // }
                                            }}
                                        >
                                            Acepto
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
