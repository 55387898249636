
import imagen_home_banner from  "../../assets/img/Logotipo-Avante.png";
import imagen_direction_banner from  "../../assets/img/Grupo8.png";

const Index = {
	HomeInitial: imagen_home_banner,
	DirectionInitial: imagen_direction_banner
}

export default Index;
