import React from 'react';
import './Header.scss';
import Imagenes from '../../components/Img/Index';
const Header = () => {
    return (
        <div className="bg-gray-header">
            <div className="row header">
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center">
                    <img src={Imagenes.HomeInitial} alt="avante-logo" />
                </div>
            </div>
        </div>
    );
};

export default Header;
