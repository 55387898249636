import React from 'react';
import Layout from '../Layout/Layout';
import { Stepper } from '../Stepper/Stepper';
import '../Contrato/Contrato.scss';
import Imagenes from '../../components/Img/Index';
import { useHistory } from 'react-router-dom';

export const Thanks = () => {
    const history = useHistory();
    return (
        <>
            <Layout>
                <Stepper step={4} />
                <div className="container d-flex justify-content-center">
                    <div className="contrato-container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-3 d-flex justify-content-center">
                                <div className="contrato-logo-box">
                                    <img
                                        src={Imagenes.DirectionInitial}
                                        style={{ width: '40px', height: '40px' }}
                                        alt="avante-logo-secondary"
                                    />
                                </div>
                            </div>
                            <div className="col-12"></div>
                            <div className="col-8">
                                <div className="contrato-text">
                                    Hemos enviado los documentos generados al correo electrónico registrado para su
                                    firma.
                                </div>
                            </div>
                            <div className="col-12 mt-5">
                                <div className="row">
                                    <div className="col-4"></div>
                                    <div className="col-4">
                                        <div
                                            className="btn btn-primary btn-avante-primary btn-home d-flex align-items-center justify-content-center"
                                            onClick={() => {
                                                history.push('/final');
                                            }}
                                        >
                                            Continuar
                                        </div>
                                    </div>
                                    <div className="col-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};
