import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './ModalAnexoN2.scss';
import iconClose from '../../assets/svg/icon-close.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeAnexoN2Field,
    changeDatosFinalesField,
    // changeErrAnexoN2Field
} from '../../redux/reducers/juridicaForm';
// const fields = [
//     'nombreRepresentanteLegal',
//     'nroCedulaId',
//     'nombreRazonSocial',
//     'nroRut',
//     'nroIdTributaria',
//     'nroRutExtranjero',
// ];
export const ModalAnexoN2 = ({ show, setShow }: any) => {
    const dispatch = useDispatch();
    const anexoN2Data = useSelector((state: any) => state.juridicaForm.anexoN2);
    const errAnexoN2Data = useSelector((state: any) => state.juridicaForm.errAnexoN2);
    // const validateAnexoN2Data = () => {
    //     let isValid = true;
    //     for (let i in fields) {
    //         if (anexoN2Data[fields[i]] === '') {
    //             dispatch(changeErrAnexoN2Field({ field: fields[i], content: true }));
    //             isValid = false;
    //         } else {
    //             dispatch(changeErrAnexoN2Field({ field: fields[i], content: false }));
    //         }
    //     }
    //     if (!anexoN2Data.entidadNacional) {
    //         if (anexoN2Data.domicilioExtranjero === '') {
    //             dispatch(changeErrAnexoN2Field({ field: 'domicilioExtranjero', content: true }));
    //             isValid = false;
    //         } else {
    //             dispatch(changeErrAnexoN2Field({ field: 'domicilioExtranjero', content: false }));
    //         }
    //     }
    //     return isValid;
    // };
    return (
        <Modal isOpen={show} toggle={() => setShow(!show)} size="xl" centered>
            <ModalBody className="p-0 ">
                <div className="container">
                    <div className="row py-3 px-2">
                        <div className=" col-12 pru ">
                            <img
                                onClick={() => setShow(!show)}
                                src={iconClose}
                                alt="close-icon"
                                style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                    <div className="container anexo-b-page">
                        <div className="row mb-5">
                            <div className="col-12">
                                <div className="row d-flex justify-content-center align-center mt-5">
                                    <div className="col-xl-3 col-lg-3 col-md-12 col-xs-6 anexo-b-title">
                                        <span>ANEXO N°2</span>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center align-center">
                                    <div className="col-xl-6 col-lg-12 col-md-12 col-xs-6 anexo-b-subtitle-gray">
                                        (FICHA CLIENTE PERSONA JURÍDICA / ENTIDAD)
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center align-center">
                                    <div className="col-xl-6 col-lg-12 col-md-12 col-xs-6 anexo-b-subtitle">
                                        DECLARACIÓN DE INVERSIONISTA CALIFICADO
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row  d-flex justify-content-center align-center">
                            <div className="col-xl-10 col-lg-10 col-md-12 col-xs-6 anexo-content-b mt-2">
                                <div>
                                    <span>
                                        La Suscrita, en su calidad de Inversionista Calificado de acuerdo a lo
                                        establecido en el numeral 8. de la Sección II de la Norma de Carácter General Nº
                                        216, de 2008, (en adelante, la “NCG 216”), de la Superintendencia de Valores y
                                        Seguros, hoy Comisión para el Mercado Financiero (en adelante, la “CMF”), por el
                                        presente instrumento viene en declarar bajo juramento su intención expresa de
                                        participar e invertir en el mercado financiero a través de instrumentos con
                                        condiciones y requisitos específicos, entre los cuales se encuentran, a título
                                        meramente ejemplar y enunciativo, valores de oferta pública de emisores
                                        nacionales y/o extranjeros; cuotas o series de cuotas de fondos mutuos y/o de
                                        fondos de inversión nacionales dirigidos a Inversionistas Calificados; acciones
                                        o participaciones en vehículos de inversión colectiva extranjeros; contratos de
                                        derivados celebrados en Chile o en el extranjero con el propósito de cubrir el
                                        riesgo de fluctuaciones de tasas, precios y tipos de cambio de los activos
                                        administrados; contratos sobre productos agropecuarios que se transen en bolsas
                                        de productos nacionales; facturas que sean adquiridas en centros bursátiles
                                        nacionales, que cuenten con autorización para transar este tipo de activos;
                                        notas estructuradas; contratos de préstamo de valores; y cualesquiera otro
                                        instrumento financiero que pueda desarrollarse en el futuro.
                                        <br />
                                        <br />
                                        Asimismo, la Suscrita declara expresamente que entiende a cabalidad y acepta
                                        todos los riesgos propios de los mercados especiales y sus instrumentos, y que
                                        cumple con los requisitos establecidos en la NCG N° 216 para ser considerada
                                        como Inversionista Calificado.
                                        <br />
                                        <br /> A mayor abundamiento, y a fin de precisar lo previamente referido, la
                                        Suscrita declara ser (por favor marcar con una “X” lo que corresponda):
                                    </span>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-1"></div>
                                    <div className="col-xl-7 col-lg-7 col-md-12 col-xs-12">
                                        <div className="row">
                                            <div className="col-12 d-flex align-items-center justify-content-start pr-4 ">
                                                <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                                                    <input
                                                        checked={anexoN2Data.entidadNacional === 'X'}
                                                        className="input-radio mr-3"
                                                        type="radio"
                                                        name="USPerson"
                                                        value="Si"
                                                        onChange={() =>
                                                            dispatch(
                                                                changeAnexoN2Field({
                                                                    field: 'entidadNacional',
                                                                    content: 'X',
                                                                })
                                                            )
                                                        }
                                                    />
                                                    Una persona jurídica o entidad nacional
                                                </span>
                                            </div>
                                            <div className="col-6"></div>
                                            <div className="col-12 mb-2"></div>
                                            <div className="col-12 d-flex align-items-center justify-content-start ">
                                                <div className="d-flex align-items-center justify-content-end">
                                                    <span className="input-radio-box input-radio-box-text ml-2 mb-2">
                                                        <input
                                                            checked={anexoN2Data.entidadNacional !== 'X'}
                                                            className="input-radio mr-3"
                                                            type="radio"
                                                            name="USPerson"
                                                            value="No"
                                                            onChange={() =>
                                                                dispatch(
                                                                    changeAnexoN2Field({
                                                                        field: 'entidadNacional',
                                                                        content: 'O',
                                                                    })
                                                                )
                                                            }
                                                        />
                                                        Una persona jurídica o entidad extranjera, con domicilio en
                                                        (especificar):
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12">
                                        <div className="row">
                                            <div className="col-12" style={{ height: '20px' }}></div>
                                            <div className="col-12 mb-2"></div>
                                            <div className="col-12">
                                                <input
                                                    disabled={anexoN2Data.entidadNacional === 'X'}
                                                    className={`input-activo  mr-3 ${
                                                        errAnexoN2Data.domicilioExtranjero &&
                                                        !anexoN2Data.entidadNacional
                                                            ? 'input-error'
                                                            : ''
                                                    }`}
                                                    type="text"
                                                    name="USPerson"
                                                    value={anexoN2Data.domicilioExtranjero}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoN2Field({
                                                                field: 'domicilioExtranjero',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {errAnexoN2Data.domicilioExtranjero && !anexoN2Data.entidadNacional && (
                                                    <p className="error-text error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3 d-flex justify-content-end ">
                                    <div className="col-12 anexo-content-b mt-2">
                                        <div>
                                            <span>
                                                Que a esta fecha cuenta con inversiones financieras en valores
                                                susceptibles de ser ofrecidos públicamente en Chile o en el extranjero,
                                                por un monto igual o superior a UF 2.000 (dos mil Unidades de Fomento) y
                                                que, adicionalmente, cumple con al menos uno de los siguientes
                                                requisitos (por favor marcar con una “X” lo que corresponda):
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-xl-10 col-lg-10 col-md-12 col-xs-12">
                                        <div className="input-checkbox-box">
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoN2Data.checkbox1 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="Si"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoN2Field({
                                                                field: 'checkbox1',
                                                                content: `${anexoN2Data.checkbox1 === 'X' ? 'O' : 'X'}`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    Cuenta con activos iguales o superiores a UF 10.000 (diez mil
                                                    Unidades de Fomento).
                                                </label>
                                            </div>
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoN2Data.checkbox2 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="No"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoN2Field({
                                                                field: 'checkbox2',
                                                                content: `${anexoN2Data.checkbox2 === 'X' ? 'O' : 'X'}`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    Ha realizado transacciones en el mercado de valores por un monto
                                                    individual igual o superior a UF 1.000 (mil Unidades de Fomento) y
                                                    con una frecuencia mínima de 20 (veinte) operaciones trimestrales,
                                                    durante los últimos 4 (cuatro) trimestres.
                                                </label>
                                            </div>
                                            <div className="my-4 d-flex flex-row">
                                                <input
                                                    checked={anexoN2Data.checkbox3 === 'X'}
                                                    className="input-radio mr-3 mt-1"
                                                    type="checkbox"
                                                    name="requisitos"
                                                    value="No"
                                                    onChange={() =>
                                                        dispatch(
                                                            changeAnexoN2Field({
                                                                field: 'checkbox3',
                                                                content: `${anexoN2Data.checkbox3 === 'X' ? 'O' : 'X'}`,
                                                            })
                                                        )
                                                    }
                                                />
                                                <label className="input-checkbox input-checkbox-text">
                                                    Cuenta con el conocimiento necesario para entender los riesgos que
                                                    conlleva participar en mercados, colocaciones u ofertas con
                                                    requisitos, condiciones, parámetros y riesgos distintos a los
                                                    propios del mercado general de valores.
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row anexo-b-content mt-2  d-flex justify-content-end ">
                                            <div className="col-12 anexo-content-b">
                                                <div>
                                                    <span>Este conocimiento fue adquirido a consecuencia de:</span>
                                                </div>
                                            </div>
                                            <div className="col-xl-10 col-lg-10 col-md-12 col-xs-12">
                                                <div className="input-checkbox-box">
                                                    <div className="my-4 d-flex flex-row">
                                                        <input
                                                            checked={anexoN2Data.checkbox4 === 'X'}
                                                            className="input-radio mr-3 mt-1"
                                                            type="checkbox"
                                                            name="requisitos"
                                                            value="Si"
                                                            onChange={() =>
                                                                dispatch(
                                                                    changeAnexoN2Field({
                                                                        field: 'checkbox4',
                                                                        content: `${
                                                                            anexoN2Data.checkbox4 === 'X' ? 'O' : 'X'
                                                                        }`,
                                                                    })
                                                                )
                                                            }
                                                        />
                                                        <label className="input-checkbox input-checkbox-text">
                                                            Haber cursado una carrera profesional o haber realizado
                                                            estudios posteriores a ella relacionados con el área de
                                                            negocios o inversiones.
                                                        </label>
                                                    </div>
                                                    <div className="my-4 d-flex flex-row">
                                                        <input
                                                            checked={anexoN2Data.checkbox5 === 'X'}
                                                            className="input-radio mr-3 mt-1"
                                                            type="checkbox"
                                                            name="requisitos"
                                                            value="No"
                                                            onChange={() =>
                                                                dispatch(
                                                                    changeAnexoN2Field({
                                                                        field: 'checkbox5',
                                                                        content: `${
                                                                            anexoN2Data.checkbox5 === 'X' ? 'O' : 'X'
                                                                        }`,
                                                                    })
                                                                )
                                                            }
                                                        />
                                                        <label className="input-checkbox input-checkbox-text">
                                                            Haber desempeñado, por a lo menos 2 (dos) años consecutivos,
                                                            un cargo profesional que requiere de tal conocimiento para
                                                            su ejercicio, tales como: mesas de dinero; departamentos
                                                            dedicados a realizar análisis financiero; asesorías
                                                            financieras; administración y gestión de recursos,
                                                            vinculados a decisiones de inversión en un Inversionista
                                                            Calificado; asesorías jurídicas en materias de inversiones;
                                                            u otras áreas o funciones afines.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 anexo-content-b">
                                        <span>
                                            Finalmente, declara la Suscrita que ha sido detalladamente informada por
                                            AVANTE ADMINISTRADORA GENERAL DE FONDOS S.A. acerca de las características y
                                            riesgos particulares de los mercados e instrumentos objeto de inversión y,
                                            en particular, lo siguiente:
                                        </span>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-end">
                                    <div className="col-12">
                                        <ul>
                                            <li className="my-4">
                                                Que los valores y sus emisores pueden contar con requisitos, parámetros
                                                y riesgos diferentes a aquéllos correspondientes al mercado general de
                                                valores, razón por la que sólo pueden participar Inversionistas
                                                Calificados.
                                            </li>
                                            <li className="my-4">
                                                Que la Comisión para el Mercado Financiero, en consideración a
                                                lascaracterísticas de determinados emisores de valores, al volumen de
                                                susoperaciones u otras circunstancias, está legalmente facultada para
                                                requerirlesmenor información y circunscribir la transacción de sus
                                                valores a los grupos de inversionistas que determine.
                                            </li>
                                            <li className="my-4">
                                                Que, en virtud de lo anterior, la Comisión para el Mercado Financiero, a
                                                través de Normas de Carácter General, ha establecido menores requisitos
                                                de información para aquellos emisores que pretendan hacer oferta de sus
                                                valores en mercado especiales o dirigirlas exclusivamente a
                                                Inversionistas Calificados.
                                            </li>
                                            <li className="my-4">
                                                Que, tratándose de valores extranjeros, la información legal, económica
                                                y financiera corresponderá a aquella que el emisor entrega en sus
                                                mercados de origen o en otros mercados donde los valores se negocian, y
                                                que la oferta pública de valores extranjeros en Chile requiere de parte
                                                de la Comisión para el Mercado Financiero únicamente de la inscripción
                                                en el Registro de Valores Extranjeros que lleva al efecto, por lo cual
                                                sólo le compete la regulación y supervisión de la oferta pública que de
                                                dichos títulos se haga en Chile, con las limitaciones que conlleva que
                                                los emisores respectivos tengan domicilio en el extranjero.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-12 anexo-content-b">
                                        <span>
                                            La presente declaración se suscribe en duplicado, quedando un ejemplar
                                            firmado en poder del Inversionista y el otro en poder de Avante
                                            Administradora General de Fondos S.A.
                                        </span>
                                    </div>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-12">
                                        <span className="declarante-form-title">
                                            Declarante cliente persona jurídica / entidad:
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {errAnexoN2Data.nombreRepresentanteLegal ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    Nombre del Representante Legal del Inversionista:
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        errAnexoN2Data.nombreRepresentanteLegal ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoN2Data.nombreRepresentanteLegal}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoN2Field({
                                                                field: 'nombreRepresentanteLegal',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {errAnexoN2Data.nombreRepresentanteLegal && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {' '}
                                                    {errAnexoN2Data.nroCedulaId ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    N° Cédula de Identidad del Representante Legal:
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        errAnexoN2Data.nroCedulaId ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoN2Data.nroCedulaId}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoN2Field({
                                                                field: 'nroCedulaId',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {errAnexoN2Data.nroCedulaId && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {errAnexoN2Data.nombreRazonSocial ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    Nombre o Razón Social del Inversionista: pp.
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        errAnexoN2Data.nombreRazonSocial ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoN2Data.nombreRazonSocial}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoN2Field({
                                                                field: 'nombreRazonSocial',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {errAnexoN2Data.nombreRazonSocial && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {errAnexoN2Data.nroRut ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    N° RUT del Inversionista:
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        errAnexoN2Data.nroRut ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoN2Data.nroRut}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoN2Field({
                                                                field: 'nroRut',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {errAnexoN2Data.nroRut && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {errAnexoN2Data.nroIdTributaria ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    N° de Identificación Tributaria del Inversionista Extranjero:
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        errAnexoN2Data.nroIdTributaria ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoN2Data.nroIdTributaria}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoN2Field({
                                                                field: 'nroIdTributaria',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {errAnexoN2Data.nroIdTributaria && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                            <div className="error-label-NB col-lg-6 col-md-12 col-xs-12 input-box">
                                                <label htmlFor="">
                                                    {errAnexoN2Data.nroRutExtranjero ? (
                                                        <span className="error-color">*</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    N° RUT del Inversionista Extranjero:
                                                </label>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-xs-12">
                                                <input
                                                    className={`input-activo ${
                                                        errAnexoN2Data.nroRutExtranjero ? 'input-error' : ''
                                                    }`}
                                                    type="text"
                                                    value={anexoN2Data.nroRutExtranjero}
                                                    onChange={(e) =>
                                                        dispatch(
                                                            changeAnexoN2Field({
                                                                field: 'nroRutExtranjero',
                                                                content: e.target.value,
                                                            })
                                                        )
                                                    }
                                                />
                                                {errAnexoN2Data.nroRutExtranjero && (
                                                    <p className="error-text-NB error-color">*Campo obligatorio</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-lg-3 col-md-12 col-sm-4">
                                        <div
                                            className="btn btn-primary btn-avante-primary btn-home d-flex align-items-center justify-content-center"
                                            onClick={() => {
                                                // if (validateAnexoN2Data()) {
                                                setShow(!show);
                                                dispatch(changeDatosFinalesField({ field: 'anexoN2', content: true }));
                                                // }
                                            }}
                                        >
                                            Acepto
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};
