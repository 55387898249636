import React, { useEffect } from 'react';
import Layout from '../Layout/Layout';
import { Stepper } from '../Stepper/Stepper';
import './Contrato.scss';
import Imagenes from '../../components/Img/Index';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

export const Contrato = () => {
    const history = useHistory();
    const { type }: any = useParams();
    const data = useSelector((state: any) => state[type]);

    useEffect(() => {
        const sendData = async () => {
            if (type === 'naturalForm') {
                const respContratos = await axios.post('/adobe', { naturalForm: data });
                let formData = new FormData();
                if (data.aPersonales.cedulaFrente.length !== 0) {
                    for (let file in data.aPersonales.cedulaFrente) {
                        formData.append('file', data.aPersonales.cedulaFrente[file]);
                    }
                }
                if (data.aPersonales.cedulaDorso.length !== 0) {
                    for (let file in data.aPersonales.cedulaDorso) {
                        formData.append('file', data.aPersonales.cedulaDorso[file]);
                    }
                }
                if (data.representantesLegales.length !== 0) {
                    for (let repLegal in data.representantesLegales) {
                        if (data.representantesLegales[repLegal].documentos.length !== 0) {
                            for (let doc in data.representantesLegales[repLegal].documentos) {
                                formData.append('file', data.representantesLegales[repLegal].documentos[doc]);
                            }
                        }
                    }
                }
                const test = {
                    perfil: data.perfilNatural,
                    preguntas: data.preguntasNatural,
                    puntaje: data.puntajeNatural.puntaje,
                    nombre: `${data.aPersonales.nombres} ${data.aPersonales.apellidoPaterno} ${data.aPersonales.apellidoMaterno}`,
                };

                formData.append('test', JSON.stringify(test));
                const respEmail = await axios.post('/adobe/email', formData, {
                    headers: { 'content-type': 'multipart/form-data' },
                });
                if (respEmail && respContratos) {
                    history.push('/thanks');
                }
            } else {
                const juridicaResp = await axios.post('/adobe', { juridicaForm: data });
                const test = {
                    perfil: data.perfilJuridica,
                    preguntas: data.preguntasJuridica,
                    puntaje: data.puntajeJuridica.puntaje,
                    nombre: `${data.aCliente.nombreRazonSocial}`,
                };
                let formData = new FormData();
                formData.append('test', JSON.stringify(test));
                if (data.aCliente.rutSociedadDorso) {
                    formData.append('file', data.aCliente.rutSociedadDorso[0]);
                }

                if (data.aCliente.rutSociedadFrente) {
                    formData.append('file', data.aCliente.rutSociedadFrente[0]);
                }

                for (let repLegal in data.representantesLegales) {
                    if (data.representantesLegales[repLegal].rutRepresentanteLegalFrente.length !== 0) {
                        for (let i in data.representantesLegales[repLegal].rutRepresentanteLegalFrente) {
                            formData.append(
                                'file',
                                data.representantesLegales[repLegal].rutRepresentanteLegalFrente[i]
                            );
                        }
                    }
                    if (data.representantesLegales[repLegal].rutRepresentanteLegalDorso.length !== 0) {
                        for (let i in data.representantesLegales[repLegal].rutRepresentanteLegalDorso) {
                            formData.append('file', data.representantesLegales[repLegal].rutRepresentanteLegalDorso[i]);
                        }
                    }
                }

                await axios.post('/adobe/email/test', formData, {
                    headers: { 'content-type': 'multipart/form-data' },
                });
                if (juridicaResp) {
                    history.push('/thanks');
                }
            }
            await axios.post('/britech/investor', { naturalForm: data });
        };
        sendData();
    }, [data, type, history]);

    return (
        <>
            <Layout>
                <Stepper step={3} />
                <div className="container d-flex justify-content-center">
                    <div className="contrato-container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-3 d-flex justify-content-center">
                                <div className="contrato-logo-box">
                                    <img
                                        // className="imgr"
                                        src={Imagenes.DirectionInitial}
                                        style={{ width: '40px', height: '40px' }}
                                        alt="avante-logo-secondary"
                                    />
                                </div>
                            </div>
                            <div className="col-12"></div>
                            <div className="col-8">
                                <div className="contrato-text">Estamos generando tu contrato...</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};
