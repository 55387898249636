import React from 'react'
import './Footer.scss'
import Imagenes from '../../components/Img/Index';

const Footer = () => {
    return (
       <div className="row footer">
           <div >
              <img  src={Imagenes.DirectionInitial} className="imgdirection imgen-footer" alt='avante-logo-secondary' />
                <p className='textfooter'>COPYRIGHT @ 2021 AVANTE.
                </p>
            </div>
            
        </div>
    )
}

export default Footer
